body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

article figure {
  margin: 0;
}

article p {
  line-height: 1.5rem;
  font-size: .875rem;
}

article h1 {
  font-size: 2rem;
  line-height: 2.75rem;
  margin: 10px 0;
}

article h2 {
  font-size: 1.15rem;
}

article figure .image div.img {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  text-align: center;
}

article figure .image {
  text-align: center;
}

article figure .image img {
  max-width: 650px;
  border-radius: 8px;
  width: auto;
  max-height: 400px;
}

article section.image img {
  max-width: 100%;
  border-radius: 8px;
  max-height: 500px
}

article section.hero {
  text-align: center;
}

article figure .caption {
  font-size: .75rem;
  margin-top: 8px;
}

article blockquote {
  font-size: 1rem;
  margin: 30px auto;
  font-style: italic;
  padding: 1.2em 30px 1.2em 35px;
  line-height: 1.6;
  position: relative;
  width: 80%;
}

article blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #000;
  font-size: 5rem;
  position: absolute;
  left: -5px;
  top: -20px
}

article blockquote::after {
  content: ''
}

article blockquote span {
  display: block;
  font-style: normal;
  font-weight: 600;
  margin-top: .5rem;
  font-size: .875rem;
}

article section.image {
  margin: 50px auto;
  width: 60%;
}

article section.image img {
  max-width: 100%;
  border-radius: 8px;
  max-height: 500px;
}

article section.image .caption {
  text-align: left;
  font-size: .875rem;
  font-style: italic;
}

article .meta-data {
  display: flex;
  align-items: center;
  font-size: .75rem;
  margin-bottom: 20px;
  margin-top: 5px;
}

article .meta-data.center {
  justify-content: center;
  margin-top:20px;
  flex-wrap: wrap;
}

article .meta-data div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-top: 10px;
}

article .meta-data svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
article .ingredients, article .method {
  margin: 30px 0;
}

article ul {
  padding-left: 20px;
}

article ol {
  padding: 0;
}

article ul li {
  font-size: .875rem;
  margin: 8px 0;
}

article ol li {
  margin: 12px 0;
  font-size: .875rem;
  line-height: 1.25rem;
  padding-left: 0;
  list-style-type: none;
}

article .ingredients strong {
  font-size: .875rem;
}

article h3 {
  font-weight: 600;
  font-size: 1.5rem;
  margin: 24px 0 -18px 0;
}